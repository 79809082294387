// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#solutions {
  padding: 10px;
  background: #ffffff;
}

.solution {
  list-style: none;
  padding: 6px 0px;
}

.solution:hover {
  cursor: pointer;
}

.solution.underlined {
  text-decoration: underline;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/studio/Solutions.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":["#solutions {\n  padding: 10px;\n  background: #ffffff;\n}\n\n.solution {\n  list-style: none;\n  padding: 6px 0px;\n}\n\n.solution:hover {\n  cursor: pointer;\n}\n\n.solution.underlined {\n  text-decoration: underline;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tools {
  padding: 10px;
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/studio/Solution/Sideview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":["#tools {\n  padding: 10px;\n  background: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react'

import './Header.css';

export const Header = () => {
  return (
    <header id='header'>
      <h1>Surface Studio</h1>
    </header>
  )
}

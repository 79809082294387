// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#grid {
  display: grid;
  grid-template-columns: 300px 5fr 2fr;
  grid-template-rows: min-content;
  height: calc(100% - 100px);
  background: #efefef;
  column-gap: 10px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/studio/Studio.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;EACpC,+BAA+B;EAC/B,0BAA0B;EAC1B,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["#grid {\n  display: grid;\n  grid-template-columns: 300px 5fr 2fr;\n  grid-template-rows: min-content;\n  height: calc(100% - 100px);\n  background: #efefef;\n  column-gap: 10px;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
